@use "@/_styles/theme" as *;

.header
{
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10;

	@include flex(column);

	background-color: $colorNeutralBackground1;
	padding: $spacingS $spacingM;

	// Header container
	> div
	{
		@include maxCenter;
		@include flex(row);
		@include align(center, space-between);

		gap: $spacingM;

		.navigation
		{
			@media screen and (max-width: 1040px)
			{
				display: none;
			}
		}

		.socials
		{
			flex-grow: 1;
			justify-content: flex-end;
		}

		.sidemenu
		{
			@media screen and (min-width: 1041px)
			{
				display: none;
			}
		}

		@media screen and (max-width: 640px)
		{
			.socials,
			.resume
			{
				display: none;
			}
		}
	}
}
