@use "@/_styles/theme" as *;

.section
{
	@include body2($fontFamilyBaseAlt);
	color: $colorNeutralForeground2;
	align-items: center;

	@include centerTwo;

	> div:first-child
	{
		@include flex(column);
		gap: $spacingM;
	}

	> img
	{
		height: auto;
		width: 100%;
		max-width: 400px;
		justify-self: center;

		border-radius: $borderRadiusMedium;
		box-shadow: $shadow2;
	}
}
