@use "@/_styles/theme" as *;

.page
{
	@include flex(column);
	gap: $spacingXL;
	max-width: unset;

	> article
	{
		@include flex(column);
		gap: 8vw; // Bigger gap on bigger devices
	}

	h2
	{
		@include title1($fontFamilyBaseAlt);
		color: $colorNeutralForeground1;
	}

	h3
	{
		@include title2($fontFamilyBaseAlt);
		color: $colorNeutralForeground1;
	}

	section
	{
		padding: $spacingM;
		padding-top: calc($spacingM + 56px); // spacingM + header height
	}
}
